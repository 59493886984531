export enum ReportingStateEnum {
  Future = 'Future',
  Overdue = 'Overdue',
  Submitted = 'Submitted',
  Received = 'Received',
}

export interface ComplianceItemReportDTO {
  id: number;
  name: string;
  isAvailableFromPublicSources: boolean;
  isReviewedByNT: boolean;
}

export interface ReportingSubmitUpdateDTO {
  complianceReport: ComplianceReportDTO;
  complianceDocuments: ComplianceDocumentUpdateDTO[];
}

export interface ComplianceReportDTO {
  id?: number;
  complianceScheduleItemID?: number;
  comment?: string;
  hasConfirmedNoEventOfDefault?: boolean;
  informationCovenantStates?: InformationCovenantStatesDTO[];
}

export interface ComplianceDocumentUpdateDTO {
  id: number;
  isPublishRequested: boolean;
}

export interface InformationCovenantStatesDTO {
  id: number;
  isAvailableFromPublicSources?: boolean;
}

export interface ReportingDetailsDTO {
  id: number;
  hasConfirmedNoEventOfDefault: boolean | null;
  comment: string;
  complianceScheduleItemID: number;
  isBeingReviewedByNT: boolean;
  complianceItemReports: ComplianceItemReportDTO[];
  complianceDocuments: ComplianceDocumentDTO[];
}

export interface ReportingSubmitNewDTO {
  complianceScheduleItemID: number;
  comment: string;
  hasConfirmedNoEventOfDefault: boolean;
  informationCovenantStates: InformationCovenantStatesDTO[];
}

export interface ComplianceDocumentDTO {
  id: number;
  fileName: string;
  isPublishRequested: boolean;
  uploadedBy: string;
  fileType: string;
}

export interface ReportingEventDTO {
  id: number;
  deadlineDate: string;
  referenceDate: string;
  name: string;
  reportingState: ReportingStateEnum;
}
