import { Box, Typography } from '@mui/material';
import { Table } from '@nordictrustee/nt-ui-library';
import { LoadingContainer } from 'components/Loaders';
import { ComplianceItemReportDTO } from '../../reportingApi.types';
import { CheckedState } from '../CheckedState/CheckedState';
import { centerColumnText, firstColPadding } from '../tableStyles';

export const InformationCovenants = ({ informationCovenants, isLoading }: { informationCovenants: ComplianceItemReportDTO[] | undefined, isLoading: boolean }) => {
  const columns: Table.Column<ComplianceItemReportDTO>[] = [
    {
      field: 'name',
      title: 'Documentation',
      ...firstColPadding
    },
    {
      field: 'isAvailableFromPublicSources',
      title: 'Available from public sources',
      width: '35%',
      render: ({ isAvailableFromPublicSources }) => CheckedState(isAvailableFromPublicSources),
      ...centerColumnText
    },
    {
      field: '',
      title: '',
      width: '20%'
    }
  ];

  return (
    <Box>
      <Box my={2} mx={2}>
        <Typography variant="h4">Required Deliveries</Typography>
      </Box>
      <LoadingContainer loading={isLoading} type="list" rows={2} cols={2}>
        <Table.Root
          columns={columns}
          data={informationCovenants ?? []}
          options={{
            paging: false,
            search: false,
            toolbar: false,
            maxBodyHeight: '70vh',
            actionsColumnIndex: -1,
            searchFieldAlignment: 'left',
            pageSizeOptions: [10, 25, 50, 100],
            rowStyle: { padding: '0 1rem' },
          }}
        />
      </LoadingContainer>
    </Box>
  );
};